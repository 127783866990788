import { useState, useEffect } from 'react';

import { t } from '../../utils/ReactSwitchLangWrapper';
import MainLayout from '../../components/MainLayout';
import LoginForm from '../../components/auth/LoginForm';
import ConfirmEmailForm from '../../components/auth/ConfirmEmailForm';
import { checkSession } from '../../components/Router';
import { events, logAmpEvent } from '../../utils/Amplitude';
import { AUTH_STEP } from '../../utils/Constants';
import { ItalicizeInterac } from '../../components/ReplaceTextWithElement';
import style from '../../styles/AuthForm.module.scss';
import { isProduction } from '../../utils/RuntimeEnv';
import { ExternalLink } from '../../components/Link';
import PlatformLogin from '../../components/auth/PlatformLogin';

function Login() {
  const [step, setStep] = useState(AUTH_STEP.LOGIN);

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_LOGIN_PAGE);
    // Event snippet for DirectPay Partner Portal - Login Page View conversion page
    if (isProduction) window.gtag?.('event', 'conversion', { send_to: 'AW-10822626779/87alCNDHiokYENvb0Kgo' });
  }, []);

  return (
    <MainLayout
      authPage
      showLanguageSwitcher
      title={t(`PageTitle_${step.replace(/\s+/g, '')}`)}
    >
      <div>
        <div className={style.authLandingContainer}>
          <h1>
            <ItalicizeInterac text={t('Login_Landing_Title')} />
          </h1>
          <p>
            <span>{`${t('Login_Landing_Subtitle')} `}</span>
          </p>
          <ExternalLink
            href={t('Link_DirectPay')}
            text={t('Login_Landing_LearnMore')}
            ampEvent={events.USER_CLICKED_LEARN_MORE_ABOUT_DIRECTPAY_LINK}
          />
        </div>
      </div>
      <div className={style.authForm}>
        <div className={style.formContainer}>
          <div className={style.fieldsContainer}>
            {step === AUTH_STEP.LOGIN ? (
              <>
                <LoginForm setStep={setStep} />
                <hr className={style.authDivider} />
                <PlatformLogin />
              </>
            ) : (
              <ConfirmEmailForm
                setStep={setStep}
                process={AUTH_STEP.LOGIN}
              />
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default checkSession(Login, true, true);
export { getStaticPaths, getStaticProps } from '../../utils/DynamicRoutes';
