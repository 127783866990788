import { Form } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { t, translate } from '../../utils/ReactSwitchLangWrapper';
import { Default } from '../Field';
import Button from '../Button';
import { events, logAmpEvent } from '../../utils/Amplitude';
import { AUTH_API, AUTH_STEP } from '../../utils/Constants';
import style from '../../styles/AuthForm.module.scss';
import ResendEmail from './ResendEmail';
import { validateVerificationCode } from '../../utils/Validation';
import { invalidFormAlert } from '../../utils/Helpers';
import { genCognitoErrHandler, useCognito } from '../../utils/Cognito';
import { useRouter } from '../Router';
import ErrorAlert from '../alerts/ErrorAlert';
import { setScreenReaderAlert } from '../../utils/Accessibility';

function ConfirmEmailForm({ setStep, process }) {
  const cognito = useCognito();
  const [errorMsg, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const email = useSelector((state) => state.auth.email);
  const router = useRouter();

  const resendEmail = (async () => {
    setError('');
    return cognito
      .resendConfirmationCode(email)
      .then(() => {
        logAmpEvent(events.API_SUCCESS, { Call: `Cognito: ${AUTH_API.RESEND_CONFIRM_EMAIL}` });
        return true;
      })
      .catch(genCognitoErrHandler(AUTH_API.RESEND_CONFIRM_EMAIL, setError));
  });

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_CONFIRM_EMAIL_PAGE);

    // only call if coming from login
    if (process === AUTH_STEP.LOGIN) resendEmail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (values) => {
    setLoading(true);
    setError('');
    cognito
      .confirmRegistration(email, values.verificationCode)
      .then(() => {
        setScreenReaderAlert(t('ScreenReaderAlert_ConfirmEmailSuccess'));
        setSuccessful(true);
        logAmpEvent(events.API_SUCCESS, { Call: `Cognito: ${AUTH_API.CONFIRM_EMAIL}` });
        if (process === AUTH_STEP.LOGIN) {
          setStep(AUTH_STEP.LOGIN);
        } else {
          router.push('/login');
        }
      })
      .catch(genCognitoErrHandler(AUTH_API.CONFIRM_EMAIL, setError))
      .finally(() => setLoading(false));
  };

  const validate = (values) => {
    const errors = {};
    errors.verificationCode = validateVerificationCode(values.verificationCode);
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => validate(values)}
      render={(
        { handleSubmit, invalid, errors } // render form here
      ) => (
        <form
          onSubmit={(event) => {
            invalidFormAlert(invalid, errors, true);
            handleSubmit(event);
          }}
        >
          <ErrorAlert msg={errorMsg} fullWidth />

          {process === AUTH_STEP.REGISTER && (
            <Button
              fill="outline"
              text={t('ConfirmEmail_ChangeEmail_Btn')}
              className={`${style.smallButton} ${style.topButton}`}
              ampEvent={events.USER_CLICKED_CHANGE_EMAIL_REGISTER}
              onClick={() => {
                setStep(AUTH_STEP.REGISTER);
              }}
            />
          )}

          <h2 className={style.formTitle}>{t('ConfirmEmail_Title')}</h2>

          <p className={style.verifyEmailText}>
            {`${t('ConfirmEmail_Subtitle')} `}
            <span className={style.email}>{email}</span>
          </p>
          <ResendEmail cognitoFunction={resendEmail} authStep={process} />

          <Default
            label={t('Auth_Field_VerificationCode_Lbl')}
            id="VerificationCodeInput"
            name="verificationCode"
            placeholder={t('Auth_Field_VerificationCode_Placeholder')}
          />

          <Button
            text={t('ConfirmEmail_Btn')}
            type="submit"
            className={`${style.fullButton} ${style.verifyButton} ${style.authButton}`}
            loading={loading}
            successful={successful}
            ampEvent={events.USER_CLICKED_VERIFY_EMAIL_REGISTER}
          />
        </form>
      )}
    />
  );
}

export default translate(ConfirmEmailForm);
