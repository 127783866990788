/**
 * Public key of the ECDSA key pair generated with
 * [SubtleCrypto Web API](https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/generateKey),
 * [exported in jwk format](https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/exportKey).
 */
const pubJwkKey = {
  crv: 'P-384',
  ext: true,
  key_ops: ['verify'],
  kty: 'EC',
  x: 'KfUHND_PF62W_T-DMk3Jo2tkXuceFzg1Kjynsa0wqsXVfepOcxF24jzqWgHxwVcy',
  y: 'agVZB2n3zzlcEk2Q7nnw_KNBiwX4osPZKYt7KJL89BTjsXk8NWsWWiFZAAbQDOfA',
};

/**
 * Verifies the signature against the data using the hardcoded public key.
 * @param {string} signature digital signature generated with the matching private key, in base64
 * @param {string} data data that's been signed
 * @returns {Promise<boolean>}
 */
export default async function verify(signature, data) {
  try {
    return window.crypto.subtle.verify(
      { name: 'ECDSA', hash: { name: 'SHA-384' } },
      await window.crypto.subtle.importKey(
        'jwk',
        pubJwkKey,
        { name: 'ECDSA', namedCurve: 'P-384' },
        false,
        ['verify']
      ),
      Uint8Array.from(atob(signature), (c) => c.charCodeAt(0)).buffer,
      new TextEncoder().encode(data)
    ).catch(() => false);
  } catch {
    return false;
  }
}
